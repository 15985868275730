/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/
.ContactGridLayer {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50px auto 60% 50px;
    grid-template-rows: 2% 10% 12% 35% 28% 7%;
    gap: 1px 1px;
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". . . .";
    z-index: 5;
    padding-bottom: 100px;
}

/*.ContactGridLayer {*/
/*    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". Arrow Arrow ." ". . . ." "ContactBody ContactBody ContactBody ContactBody";*/
/*}*/

.ContactAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: #975E93;
    opacity: .1;
    z-index: 1;
}

/*
   ____               _                _
  / ___| ___   _ __  | |_  ___  _ __  | |_
 | |    / _ \ | '_ \ | __|/ _ \| '_ \ | __|
 | |___| (_) || | | || |_|  __/| | | || |_
  \____|\___/ |_| |_| \__|\___||_| |_| \__|

*/

.ContactBodyPage {
    grid-area: ContactBody;
    width: 100%;
    height: 100%;
    background: rgba(212, 150, 167, 0.3);
    display: grid;
    grid-template-columns: 8% 40% 4% 40% 8%;
    grid-template-rows: 5% 87% 8%;
    gap: 1px 1px;
    grid-template-areas: ". . . . ." ". ViniAboutSection . EeshanAboutSection ." ". . . . .";
    font-family: Roboto-regular, sans-serif;
}

/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.ContactGridLayer > .PageTitle {
    height: 63%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.ContactGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(151, 94, 147, 0.9);
}

.ContactGridLayer > .PageTitle > #contact_letters {
    fill: #975E93;
}


.ContactGridLayer > .PageTitle:hover #contact_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #975E93;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}


.ContactGridLayer > .PageSubTitle > a {
    font-family: Roboto-regular-italic, sans-serif;
    font-style: italic;
    text-decoration: none;
    color: #F9F1F3;
}

.ContactGridLayer > .PageSubTitle > a:hover {
    text-decoration: underline;
    color: rgba(255, 255, 255, .99);
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#contact_letters path:nth-child(1) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#contact_letters path:nth-child(2) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1118;
}

#contact_letters path:nth-child(3) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1263;
}

#contact_letters path:nth-child(4) {
    stroke-dasharray: 300;
    stroke-dashoffset: 708;
}

#contact_letters path:nth-child(5) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1037;
}

#contact_letters path:nth-child(6) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#contact_letters path:nth-child(7) {
    stroke-dasharray: 300;
    stroke-dashoffset: 708;
}

#contact_letters path:nth-child(8) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#contact_letters path:nth-child(9) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1119;
}

@media only screen and (max-width: 800px) {
    .ContactGridLayer {
        width: 100vw;
        height: 100vh;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 30% 9% 15% 5% 20% auto;*/
        grid-template-rows: 40% 10% 20% 5% 20% 5%;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ."  ". . ." ". NavBar ." ". . ." "ContactBody ContactBody ContactBody";
    }

    .ContactGridLayer > .PageTitle {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .ContactGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }
}